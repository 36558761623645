<template>
  <div>
    <vue-splash
      v-if="splashLoading"
      :show="true"
      :logo="require('../public/fsp_logo.svg')"
      color="#00bfa5"
      :size="300"
      :fixed="true"
    />
    <v-app v-else>
      <v-app-bar
        :style="$vuetify.theme.dark ? 'background-color:rgb(204,204,207);' : ''"
        app
        color="rgb(255,255,255)"
        light
        dense
        height="89"
        elevation="1"
        v-if="appBarHidden"
      >
        <div class="text-center" v-show="isActive">
          <v-menu offset-y app>
            <template v-slot:activator="{ on, attrs }">
              <v-app-bar-nav-icon
                color="black"
                dark
                v-bind="attrs"
                v-on="on"
                v-show="searchClosed"
              ></v-app-bar-nav-icon>
            </template>

            <v-list dense>
              <v-list-item>
                <v-switch
                  v-model="$vuetify.theme.dark"
                  inset
                  :label="$vuetify.theme.dark ? 'Dark Theme' : 'Light Theme'"
                  persistent-hint
                ></v-switch>
              </v-list-item>
              <v-list-item
                v-if="$route.name === 'Home'"
                @click="filter('Future Skills Partnership')"
              >
                <v-img
                  src="../public/linkedIn-logo.jpg"
                  class="burger-logo"
                  alt=""
                  contain
                />
                <v-list-item-title class="dropdown-title"
                  >FUTURE SKILLS PARTNERSHIP</v-list-item-title
                >
              </v-list-item>
              <v-list-item-group
                v-model="selectedItem"
                color="dark"
                v-if="$route.name !== 'Home'"
              >
                <v-list-item
                  @click="filter(item)"
                  v-for="(item, i) in burgerItems"
                  :key="i"
                >
                  <v-img
                    v-if="item.title === 'CodeClan'"
                    src="../public/platform-logos/codeclan-icon-logo.png"
                    class="burger-logo"
                    alt=""
                    contain
                  />
                  <v-img
                    v-else-if="item.title === 'North East Scotland College'"
                    src="../public/platform-logos/nescol-logo.png"
                    class="burger-logo"
                    alt=""
                    contain
                  />
                  <v-img
                    v-else-if="item.title === 'Opportunity North East'"
                    src="../public/platform-logos/onetechhub-logo.svg"
                    class="burger-logo"
                    alt=""
                    contain
                  />
                  <v-img
                    v-else-if="item.title === 'Robert Gordon University'"
                    src="../public/platform-logos/rgu-logo.png"
                    class="burger-logo partner-logo-rgu"
                    alt=""
                    contain
                  />
                  <v-img
                    v-else-if="item.title === 'Skills Development Scotland'"
                    src="../public/platform-logos/sds-logo.png"
                    class="burger-logo"
                    alt=""
                    contain
                  />
                  <v-img
                    v-else-if="item.title === 'The Data Lab'"
                    src="../public/platform-logos/thedatalab-logo.png"
                    class="burger-logo"
                    alt=""
                    contain
                  />
                  <v-img
                    v-else-if="item.title === 'University of Aberdeen'"
                    src="../public/platform-logos/uoa-coa-logo.png"
                    class="burger-logo"
                    alt=""
                    contain
                  />
                  <v-img
                    v-else-if="item.title === 'Future Skills Partnership'"
                    src="../public/linkedIn-logo.jpg"
                    class="burger-logo"
                    alt=""
                    contain
                  />
                  <v-img
                    v-else
                    src="../public/newlogo.png"
                    class="burger-logo"
                    alt=""
                    contain
                  />
                  <v-list-item-title class="dropdown-title">{{
                    item.title.toUpperCase()
                  }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </div>
        <v-icon
          x-large
          v-if="isMobile() === false"
          class="li-icon-desktop-headrer icon"
          :ripple="false"
          style="color: #2867B2;"
          @click="
            goToProvider(
              'https://www.linkedin.com/company/future-skills-partnership/about/'
            )
          "
          >mdi-linkedin</v-icon
        >
        <v-btn
          text
          class="burger-arrow"
          @click="goToHome"
          v-if="$route.name !== 'Home'"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <!-- <v-row v-if="isMobile() === true" class="header-logo-container">
        </v-row> -->
        <v-img
          v-if="isMobile() === true"
          src="../public/newlogo.png"
          class="current-logo-mobile"
          alt=""
        ></v-img>
        <v-img
          v-if="isMobile() === false"
          src="../public/newlogo.png"
          class="current-logo"
          alt=""
        ></v-img>
        <v-toolbar-title
          v-if="$route.name === 'Home'"
          :class="isActive ? 'news news-mobile-home' : 'news'"
          >{{ currentPage }}</v-toolbar-title
        >
        <v-toolbar-title
          v-else-if="$route.name === 'News'"
          :class="isActive ? 'news news-mobile-home' : 'news'"
          >{{ currentPage }}</v-toolbar-title
        >
        <v-toolbar-title
          v-else
          :class="isActive ? 'news news-mobile' : 'news'"
          >{{ currentPage }}</v-toolbar-title
        >
        <v-icon
          large
          v-if="isMobile() === true && $route.name === 'Home'"
          class="li-icon-current-page icon"
          :ripple="false"
          style="color: #2867B2;"
          @click="
            goToProvider(
              'https://www.linkedin.com/company/future-skills-partnership/about/'
            )
          "
          >mdi-linkedin</v-icon
        >
        <v-icon
          large
          v-if="isMobile() === true && $route.name === 'News'"
          class="li-icon-current-page icon"
          :ripple="false"
          style="color: #2867B2;"
          @click="
            goToProvider(
              'https://www.linkedin.com/company/future-skills-partnership/about/'
            )
          "
          >mdi-linkedin</v-icon
        >
        <v-spacer></v-spacer>

        <v-switch
          v-if="!isActive"
          v-model="$vuetify.theme.dark"
          inset
          class="mt-5 mr-5"
          :label="$vuetify.theme.dark ? 'Dark Theme' : 'Light Theme'"
          persistent-hint
          style="position: absolute; right: 60px;"
        ></v-switch>

        <!-- removed temporarily as it doesn't function -->
        <v-text-field
          v-if="$route.name !== 'Home' && $route.name !== 'News'"
          @focus="searchClosed = false"
          @blur="searchClosed = true"
          v-model="searchQuery"
          placeholder="Search"
          class="expanding-search mt-6"
          :class="{ closed: searchClosed && !searchQuery }"
          prepend-inner-icon="mdi-magnify"
          solo
          dense
          clearable
        ></v-text-field>
      </v-app-bar>
      <v-main>
        <v-container :class="containerClass">
          <v-navigation-drawer
            app
            width="400px"
            :style="
              $vuetify.theme.dark ? 'background-color:rgb(204,204,207);' : ''
            "
          >
            <div
              width="100%"
              :style="
                $vuetify.theme.dark ? 'background-color:rgb(204,204,207);' : ''
              "
            >
              <img
                src="../public/fsp_logo.svg"
                width="300px"
                style="padding-left: 10px; padding-top: 10px; padding-bottom: 10px;"
              />
            </div>
            <v-divider></v-divider>
            <v-list>
              <v-list-group
                v-model="item.active"
                v-for="item in items"
                :key="item.title"
                @click.stop="goTo(item)"
                no-action
                color="#75bdc7"
                :append-icon="
                  item.items && item.items.length ? '$expand' : null
                "
                class="elevation-1"
              >
                <v-list-item-content slot="activator">
                  <v-list-item-title class="item-title-content">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-group
                  active-class="border"
                  v-model="item.selectedItem"
                >
                  <v-list-item
                    v-for="subItem in item.items"
                    :key="subItem.title"
                    ripple
                    @click.stop="filter(subItem)"
                    class="partners-sublist"
                  >
                    <v-list-item-icon
                      v-if="subItem.title === 'University of Aberdeen'"
                    >
                      <v-img
                        class="partner-logo"
                        src="../public/platform-logos/uoa-coa-logo.png"
                        alt=""
                        contain
                      />
                    </v-list-item-icon>
                    <v-list-item-icon v-if="subItem.title === 'CodeClan'">
                      <v-img
                        class="partner-logo"
                        src="../public/platform-logos/codeclan-icon-logo.png"
                        alt=""
                      />
                    </v-list-item-icon>
                    <v-list-item-icon
                      v-if="subItem.title === 'Robert Gordon University'"
                    >
                      <v-img
                        class="partner-logo-rgu"
                        src="../public/platform-logos/rgu-logo.png"
                        alt=""
                      />
                    </v-list-item-icon>
                    <v-list-item-icon
                      v-if="subItem.title === 'Opportunity North East'"
                    >
                      <v-img
                        class="partner-logo"
                        src="../public/platform-logos/onetechhub-logo.svg"
                        alt=""
                      ></v-img>
                    </v-list-item-icon>
                    <v-list-item-icon
                      v-if="subItem.title === 'North East Scotland College'"
                    >
                      <img
                        class="partner-logo"
                        src="../public/platform-logos/nescol-logo.png"
                        alt=""
                      />
                    </v-list-item-icon>
                    <v-list-item-icon v-if="subItem.title === 'The Data Lab'">
                      <v-img
                        class="partner-logo"
                        src="../public/platform-logos/thedatalab-logo.png"
                        alt=""
                      />
                    </v-list-item-icon>
                    <v-list-item-icon
                      v-if="subItem.title === 'Skills Development Scotland'"
                    >
                      <v-img
                        class="partner-logo"
                        src="../public/platform-logos/sds-logo.png"
                        alt=""
                      />
                    </v-list-item-icon>
                    <v-list-item-icon v-if="subItem.title === 'All News'">
                      <v-img
                        src="../public/newlogo.png"
                        class="filter-all-logo"
                        alt=""
                      />
                    </v-list-item-icon>
                    <v-list-item-icon v-if="subItem.title === 'All Courses'">
                      <v-img
                        src="../public/newlogo.png"
                        class="filter-all-logo"
                        alt=""
                      />
                    </v-list-item-icon>
                    <v-list-item-icon v-if="subItem.title === 'All Events'">
                      <v-img
                        src="../public/newlogo.png"
                        class="filter-all-logo"
                        alt=""
                      />
                    </v-list-item-icon>
                    <v-list-item-title color="#75bdc7" class="subitem">
                      {{ subItem.title.toUpperCase() }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list-group>
            </v-list>
          </v-navigation-drawer>

          <router-view></router-view>
        </v-container>
        <v-bottom-navigation class="bottom-nav" fixed v-show="isActive">
          <!-- <v-btn v-if="['Home'].indexOf($route.name) > -1" @click="goToHome">
            <span>{{ $t("app.home") }}</span>
            <v-icon>mdi-home</v-icon>
          </v-btn> -->
          <v-btn @click="goToHome" class="bt-nav-button-home" filled>
            <span
              :class="
                $vuetify.theme.dark
                  ? 'bt-nav-button bt-nav-button-dark'
                  : 'bt-nav-button'
              "
              >{{ $t("app.home") }}</span
            >
            <v-icon filled>small mdi-home</v-icon>
          </v-btn>
          <v-btn @click="goToNews" filled>
            <span
              :class="
                $vuetify.theme.dark
                  ? 'bt-nav-button bt-nav-button-dark'
                  : 'bt-nav-button'
              "
              >{{ $t("app.news") }}</span
            >
            <v-icon filled>small mdi-book-open</v-icon>
          </v-btn>
          <!-- <v-btn @click="goToSearch" disabled>
            <span class="bt-nav-button">{{ $t("app.search") }}</span>
            <v-icon>mdi-magnify</v-icon>
          </v-btn> -->
          <v-btn @click="goToCourses" class="bt-nav-button-courses">
            <span
              :class="
                $vuetify.theme.dark
                  ? 'bt-nav-button bt-nav-button-dark'
                  : 'bt-nav-button'
              "
              >{{ $t("app.courses") }}</span
            >
            <v-icon>small mdi-bookmark</v-icon>
          </v-btn>
          <v-btn @click="goToEvents">
            <span
              :class="
                $vuetify.theme.dark
                  ? 'bt-nav-button bt-nav-button-dark'
                  : 'bt-nav-button'
              "
              >{{ $t("app.events") }}</span
            >
            <v-icon>small mdi-calendar-today</v-icon>
          </v-btn>
          <v-btn @click="goToBlog" class="bt-nav-button-post">
            <span
              :class="
                $vuetify.theme.dark
                  ? 'bt-nav-button bt-nav-button-dark'
                  : 'bt-nav-button'
              "
              >{{ $t("app.blog") }}</span
            >
            <v-icon>small mdi-post</v-icon>
          </v-btn>
        </v-bottom-navigation>
      </v-main>
      <v-snackbar
        bottom
        right
        :value="updateExists"
        :timeout="-1"
        color="primary"
      >
        An update is available
        <v-btn text @click="refreshApp">
          Update
        </v-btn>
      </v-snackbar>
      <v-footer padless v-if="isMobile() === false">
        <v-col class="text-center font-weight-thin footer" cols="12">
          <strong
            @click="goToProvider('https://www.pinnaclevl.com/')"
            style="cursor: pointer;"
            >Powered by Pinnacle</strong
          >
          <v-img
            max-height="15"
            max-width="15"
            class="footer-img"
            src="../public/pinnacle-logo.png"
          ></v-img>
        </v-col>
      </v-footer>
      <v-container
        v-if="isMobile() === true"
        fluid
        style="background-color: #f5f5f5; padding-top: 0px;"
      >
        <v-row>
          <v-col cols="12" style="padding-top: 0px;">
            <!-- You can change the COLS attribute to make columns display side-by-side -->
            <v-footer inset max-height="600" color="#f5f5f5">
              <v-col
                class="text-center font-weight-thin footer-mobile"
                cols="12"
              >
                <strong
                  @click="goToProvider('https://www.pinnaclevl.com/')"
                  style="cursor: pointer;"
                  footer-mobile
                  >Powered by Pinnacle</strong
                >
                <v-img
                  max-height="15"
                  max-width="15"
                  class="footer-img"
                  src="../public/pinnacle-logo.png"
                ></v-img>
              </v-col>
            </v-footer>
          </v-col>
        </v-row>
      </v-container>
    </v-app>
  </div>
</template>

<script>
import update from "./mixins/swUpdate";

export default {
  created() {
    this.isMobile();
    window.addEventListener("resize", this.isMobile);
  },
  destroyed() {
    window.removeEventListener("resize", this.isMobile);
  },
  name: "App",
  mixins: [update],
  data() {
    return {
      splashLoading: true,
      isActive: true,
      selectedProvider: null,
      drawer: true,
      searchClosed: true,
      selectedItem: null,
      newsItems: [{ title: "All News" }],
      partners: [
        { title: "CodeClan" },
        { title: "North East Scotland College" },
        { title: "Opportunity North East" },
        { title: "Robert Gordon University" },
        { title: "Skills Development Scotland" },
        { title: "The Data Lab" },
        { title: "University of Aberdeen" },
      ],
      sections: [
        { title: "Home" },
        { title: "News" },
        { title: "Courses" },
        { title: "Events" },
        { title: "Blog" },
      ],
      items: [
        {
          title: "HOME",
          active: false,
          items: [],
        },
        {
          title: "COURSES",
          active: false,
          items: [
            { title: "All Courses" },
            { title: "CodeClan" },
            { title: "North East Scotland College" },
            { title: "Robert Gordon University" },
            { title: "The Data Lab" },
            { title: "University of Aberdeen" },
          ],
        },
        {
          title: "NEWS",
          active: false,
          items: [
            { title: "All News" },
            { title: "CodeClan" },
            { title: "North East Scotland College" },
            { title: "Opportunity North East" },
            { title: "Robert Gordon University" },
            { title: "Skills Development Scotland" },
            { title: "The Data Lab" },
            { title: "University of Aberdeen" },
          ],
        },
        {
          title: "EVENTS",
          active: false,
          items: [
            { title: "All Events" },
            { title: "CodeClan" },
            { title: "North East Scotland College" },
            { title: "Opportunity North East" },
            { title: "Robert Gordon University" },
            { title: "Skills Development Scotland" },
            { title: "The Data Lab" },
            { title: "University of Aberdeen" },
          ],
        },
        {
          title: "BLOG",
          active: false,
          items: [],
        },
      ],
    };
  },
  computed: {
    containerClass() {
      var containerClass = "";
      if (this.$route.name === "Home") {
        containerClass += "fill-height ";
      }
      if (this.isActive) {
        containerClass += "mb-10";
      }

      return containerClass;
    },
    searchQuery: {
      get() {
        return this.$store.getters["search/getQuery"];
      },
      set(value) {
        this.$store.commit("search/setQuery", value);
      },
    },
    getSearchArticles() {
      return this.$store.getters["articles/getSearchArticles"];
    },
    getProvider() {
      return this.$store.getters["articles/getSelectedProvider"];
    },
    currentPage() {
      if (this.$route.name === "News") {
        return this.$t("app.news");
      } else {
        if (this.$route.name) {
          var page = this.$route.name.toLowerCase();
          return this.$t("app." + page);
        }
        return "1";
      }
    },
    backBtnVisible() {
      return this.$route.name === "Search" ? true : false;
    },
    appBarHidden() {
      return this.$route.name === "Article" ? false : true;
    },
    burgerItems() {
      var allItem = { title: "All News" };

      if (this.$route.name === "Courses") {
        allItem.title = "All Courses";
      } else if (this.$route.name === "Events") {
        allItem.title = "All Events";
      }

      return [
        allItem,
        {
          title: "CodeClan",
        },
        {
          title: "North East Scotland College",
        },
        {
          title: "Opportunity North East",
        },
        {
          title: "Robert Gordon University",
        },
        {
          title: "Skills Development Scotland",
        },
        {
          title: "The Data Lab",
        },
        {
          title: "University of Aberdeen",
        },
        {
          title: "Future Skills Partnership",
        },
      ];
    },
  },
  methods: {
    goToProvider(provider) {
      window.open(provider, "_blank");
    },
    titleCase(string) {
      return string[0].toUpperCase() + string.slice(1).toLowerCase();
    },
    goToHome() {
      this.$router.push({ name: "Home" });
      this.$store.commit("articles/setSelectedProvider", null);
    },
    goToNews() {
      this.$router.push({ name: "News" });
      this.$store.commit("articles/setSelectedProvider", null);
    },
    goToSearch() {
      this.$router.push("/search");
      this.$store.commit("articles/setSelectedProvider", null);
    },
    goToCourses() {
      this.$router.push("/courses");
      this.$store.commit("articles/setSelectedProvider", null);
    },
    goToEvents() {
      this.$router.push("/events");
      this.$store.commit("articles/setSelectedProvider", null);
    },
    goToBlog() {
      this.$router.push("/blog");
      this.$store.commit("articles/setSelectedProvider", null);
    },
    filter(item) {
      if (
        item.title === "Future Skills Partnership" ||
        item === "Future Skills Partnership"
      ) {
        window.open(
          "https://www.linkedin.com/company/future-skills-partnership/about/",
          "_blank"
        );
      }
      this.selectedProvider = item.title;

      // added due to the name change so it filters correctly
      if (item.title === "Opportunity North East") {
        this.$store.commit("articles/setSelectedProvider", "One Tech Hub");
      } else {
        this.$store.commit("articles/setSelectedProvider", item.title);
      }
    },
    isMobile() {
      if (window.innerWidth <= 1250) {
        this.isActive = true;
        return true;
      } else {
        this.isActive = false;
        return false;
      }
    },
    goTo(section) {
      section.selectedItem = 0;

      if (section.title === "HOME") {
        this.$router.push({ name: "Home" });
      } else if (section.title === "BLOG") {
        this.$router.push({ name: "Blog" });
      } else {
        this.$router.push(
          section.title.toLowerCase() +
            "?partner=All%20" +
            this.titleCase(section.title)
        );
      }
    },
    // close() {
    //   this.items.forEach((item) => {
    //     if (item.active) {
    //       setTimeout(function() {
    //         item.active = true;
    //       }, 100);
    //       return false;
    //     }
    //   });
    // },
  },
  mounted() {
    let scope = this;
    setTimeout(function() {
      scope.splashLoading = false;
    }, 2000);
  },
};
</script>

<style scoped>
@font-face {
  font-family: "Mont";
  src: local("Mont"),
    url(./fonts/Mont/Mont-ExtraLightDEMO.otf) format("truetype");
}
@font-face {
  font-family: "MontHeavy";
  src: local("Mont"), url(./fonts/Mont/Mont-HeavyDEMO.otf) format("truetype");
}

.footer {
  margin-left: 9%;
  /* color: #696969 */
  color: black;
}

.footer-mobile {
  margin-left: 9%;
  margin-right: 1;
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 35px;
  padding-right: 63px;
  /* color: #696969 */
  color: black;
}

.li-icon::before {
  background-color: transparent !important;
}

.li-icon::after {
  background-color: transparent !important;
}

.li-icon {
  margin-left: 15%;
  margin-bottom: 2px;
}

.li-icon-burger {
  margin-left: 30%;
  margin-bottom: 2px;
}

.li-icon-current-page {
  margin-left: 100px;
}

.burger-arrow {
}

.li-icon-current-page::before {
  background-color: transparent !important;
}

.li-icon-current-page::after {
  background-color: transparent !important;
}

/* .v-item-group.v-bottom-navigation .v-btn {
  min-width: 75px !important;
} */

.footer-img {
  margin-left: 2px;
  display: inline-block;
}

.partners-sublist {
  padding-left: 20px !important;
}

.filter-all-logo {
  height: auto;
  width: auto;
  max-width: 30px;
  max-height: 30px;
}

.list-item-filter-by {
  margin-bottom: -12px;
}

.subitem {
  margin-left: -20px;
}

.future-container {
  display: inline-block;
}

.filter-provider-title {
  /* position: absolute;
  margin-top: -3px;
  margin-left: -20px;
  font-family: "Mont", Helvetica, Arial;
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 38px;


  align-items: center;

  color: #4e2a69 !important; */
}

.filter-title-logo {
  position: absolute;
  margin-left: -10px;
  font-family: "Mont", Helvetica, Arial;
  font-style: normal;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 38px;
}

.filter-all-title {
  position: absolute;
  margin-left: -22px;
  font-family: "Mont", Helvetica, Arial;
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 38px;
  /* or 167% */

  display: flex;
  align-items: center;

  color: #4e2a69 !important;
}

.li-icon-desktop-headrer {
}

.partner-logo-rgu {
  height: auto;
  width: auto;
  max-width: 25px;
  max-height: 25px;
  background-color: #712177;
  border-radius: 25px;
}

.partner-logo {
  height: auto;
  width: auto;
  max-width: 25px;
  max-height: 25px;
}

.expanding-search {
  /* background: transparent !important; */
}

.v-input {
  transition: max-width 0.3s;
}

.closed {
  max-width: 45px;
}

.bt-nav-button {
  font-family: "Mont", Helvetica, Arial;
  font-weight: bold;
  color: #323b3c;
}

.bt-nav-button-dark {
  color: #ffffff;
}

.bt-nav-button-post {
  margin-left: -15px !important;
}

.bt-nav-button-home {
  margin-right: -15px !important;
}

.bt-nav-button-courses {
  margin-right: -15px !important;
  margin-left: -15px !important;
}

.burger-logo {
  height: auto;
  width: auto;
  max-width: 35px;
  max-height: 35px;
  margin-right: 5rem;
}

.logo {
  height: auto;
  width: auto;
  max-width: 45px;
  max-height: 45px;
}

.current-logo {
  height: auto;
  width: auto;
  max-width: 45px;
  max-height: 45px;
  margin-right: 5rem;
}

.current-logo-mobile {
  height: auto;
  width: auto;
  max-width: 45px;
  max-height: 45px;
  margin-right: 5rem;
}

.header-logo-container-mobile {
  margin-left: -20px;
}
.header-logo-container {
  margin-left: -20px;
}

.dropdown-title {
  margin-left: -70px;
  font-family: "Mont", Helvetica, Arial;
  font-style: normal;
  font-weight: bold !important;
  font-size: 12px !important;
  line-height: 38px;
}

.nav {
  position: fixed !important;
}
.news {
  /* News */

  height: 39px;
  left: 158px;
  top: 56px;

  font-family: "Mont", Helvetica, Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 38px;
  /* identical to box height, or 160% */

  display: flex;
  align-items: center;
  text-align: center;

  color: #323b3c;

  margin-left: -74px;
  padding-right: 6px;
}

.news-mobile {
  font-size: 22px;
}

.news-mobile-home {
  font-size: 22px;
  margin-right: -60px;
}

.three-dots {
  position: absolute;
  left: 82.83%;
  right: 70.83%;
  top: 54.17%;
  bottom: -12.5%;
}

.test {
  padding: 0rem;
}

.item-title-content {
  font-size: 18px !important;
  font-family: "Mont" !important;
  font-weight: bold;
  margin-left: 16px;
  margin-top: 5px;
}

.future-digital {
  font-family: "Mont" !important;
  font-weight: bold;
  font-size: 25px !important;
  margin-left: 16px;
}

.list-item-filter-by-content {
  margin-bottom: -20px;
}
</style>
