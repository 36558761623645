import axios from "axios";
const namespaced = true;

// state
const state = {
  articles: [],
  currentArticle: {},
  selectedProvider: null,
  isActive: true,
  lastPage: false,
  searchArticles: []
};

// getters
const getters = {
  getArticles: state => {
    return state.articles;
  },
  getSearchArticles: state => {
    return state.searchArticles;
  },
  getCurrentArticle: state => {
    return state.currentArticle;
  },
  getSelectedProvider: state => {
    return state.selectedProvider;
  },
  getLastPage: state => {
    return state.lastPage;
  }
};

// actions
const actions = {
  retrieveArticle(context, id) {
    axios.get("/v1/article/" + id).then(res => {
      context.commit("setCurrentArticle", res.data.article);
    });
  },
  retrievePage(context, page) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_API_ENDPOINT}` + "?page=" + page)
        .then(res => {
          if (res.status === 204) {
            context.commit("setLastPage", true);
            resolve();
          }
          context.commit("addArticles", res.data);
          resolve(res.data);
        })
        .catch(function(err) {
          reject(err);
        });
    });
  },
  retrieveFirstPage(context, page) {
    axios
      .get(`${process.env.VUE_APP_API_ENDPOINT}` + "?page=" + page)
      .then(res => {
        console.log('articles', res.data)
        context.commit("setArticles", res.data);
      });
  },
  retrieveOneArticle(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/article/" + id)
        .then(function(data) {
          let api = data.data.article;
          let apiInfo = {
            id: api.id,
            media: api.media,
            newsSource: api.newsSource,
            publishedAt: api.publishedAt,
            text: api.text
          };
          if (apiInfo.media.length === 0) {
            apiInfo.media = [
              {
                url:
                  "https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png"
              }
            ];
          }
          context.commit("addArticle", apiInfo);
          resolve(apiInfo);
        })
        .catch(function(err) {
          reject(err);
        });
    });
  }
};

// mutations
const mutations = {
  setArticles(state, data) {
    state.articles = data;
  },
  setSearchArticles(state, data) {
    state.searchArticles = data;
  },
  addArticles(state, data) {
    state.articles.push(...data);
  },
  setCurrentArticle(state, data) {
    state.currentArticle = data;
  },
  setSelectedProvider(state, data) {
    state.selectedProvider = data;
  },
  setLastPage(state, data) {
    state.lastPage = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced
};
