import Vue from "vue";
import VueSplash from "vue-splash";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
// import { makeServer } from "./server";
import i18n from "./i18n";
import VueObserveVisibility from "vue-observe-visibility";
import Snotify from "vue-snotify";
import linkify from "vue-linkify";
import VueGtag from "vue-gtag";

Vue.directive("linkified", linkify);
Vue.use(VueSplash);
Vue.use(Snotify);
Vue.use(VueObserveVisibility);
Vue.use(VueGtag, {
  config: { id: "G-FEW16K8BF1" }
}, router);

Vue.config.productionTip = false;

if (process.env.NODE_ENV === "development") {
  // makeServer();
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
