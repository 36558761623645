import axios from "axios";
const namespaced = true;

// state
const state = {
  events: [],
  eventTags: [],
};

// getters
const getters = {
  getEvents: (state) => {
    return state.events;
  },
  getEventTags: (state) => {
    return state.eventTags;
  },
};

// actions
const actions = {
  retrieveEvents(context) {
    axios.get(`${process.env.VUE_APP_API_EVENTS_ENDPOINT}`).then((res) => {
      context.commit("setEvents", res.data);
    });
  },
  retrieveEventTags(context) {
    axios.get("/v1/event/tags").then((res) => {
      context.commit("setEventTags", res.data.eventTags);
    });
  },
};

// mutations
const mutations = {
  setEvents(state, data) {
    state.events = data;
  },
  setEventTags(state, data) {
    state.eventTags = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced,
};
