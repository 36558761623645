import axios from "axios";
const namespaced = true;

// state
const state = {
  courses: [],
  courseTags: [],
  filteredCourses: []
};

// getters
const getters = {
  getCourses: state => {
    return state.courses;
  },
  getCourseTags: state => {
    return state.courseTags;
  },
  getFilteredCourses: state => {
    return state.courseTags;
  }
};

// actions
const actions = {
  retrieveCourses(context) {
    axios.get(`${process.env.VUE_APP_API_COURSES_ENDPOINT}`).then(res => {
      context.commit("setCourses", res.data);
    });
  },
  retrieveCourseTags(context) {
    axios.get("/v1/course/tags").then(res => {
      context.commit("setCourseTags", res.data.courseTags);
    });
  }
};

// mutations
const mutations = {
  setCourses(state, data) {
    state.courses = data;
  },
  setCourseTags(state, data) {
    state.courseTags = data;
  },
  setFilteredCourses(state, data) {
    state.courseTags = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced
};