import Vue from "vue";
import VueRouter from "vue-router";

const Home = () => import("@/views/Home.vue");
const News = () => import("@/views/News.vue");
const Search = () => import("@/views/Search.vue");
const Courses = () => import("@/views/Courses.vue");
const Events = () => import("@/views/Events.vue");
const Article = () => import("@/views/Article.vue");
const Blog = () => import("@/views/Blog.vue");

/**
 * stop the navigation duplicated error from being thrown
 * when going to the same route you are already on
 */
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name !== "NavigationDuplicated") throw err;
  });
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/news",
    name: "News",
    component: News
  },
  {
    path: "/search",
    name: "Search",
    component: Search
  },
  {
    path: "/courses",
    name: "Courses",
    component: Courses
  },
  {
    path: "/courses/:provider",
    name: "Courses",
    component: Courses
  },
  {
    path: "/events",
    name: "Events",
    component: Events
  },
  {
    path: "/article/:id",
    name: "Article",
    component: Article
  },
  {
    path: "/blog",
    name: "Blog",
    component: Blog
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  // define the tab title
  document.title = "Future Skills Partnership";
  if (to.name !== "Home") {
    document.title = `${to.name} - ${document.title}`;
  }

  next();
});

export default router;
