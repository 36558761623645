import Vue from "vue";
import Vuex from "vuex";
import articles from "./modules/articles";
import history from "./modules/history";
import courses from "./modules/courses";
import events from "./modules/events";
import blogs from "./modules/blogs";
import search from "./modules/search";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    articles,
    history,
    courses,
    events,
    blogs,
    search
  }
});
