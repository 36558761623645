// import axios from "axios";
const namespaced = true;

// state
const state = {
  query: "",
};

// getters
const getters = {
  getQuery: (state) => {
    return state.query;
  }
};

// actions
const actions = {
  
};

// mutations
const mutations = {
  setQuery(state, data) {
    state.query = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced,
};
