const namespaced = true;

// state
const state = {
  history: []
};

// getters
const getters = {
  getHistory: state => {
    return state.history;
  }
};

// actions
const actions = {};

// mutations
const mutations = {
  addToHistory(state, data) {
    state.history.push(data);
  },
  clearHistory(state) {
    state.history = [];
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced
};
