import axios from "axios";
const namespaced = true;

// state
const state = {
  blogPosts: [],
};

// getters
const getters = {
  getBlogPosts: state => {
    return state.blogPosts;
  }
};

// actions
const actions = {
  retrieveBlogPosts(context) {
    axios.get(`${process.env.VUE_APP_API_BLOGS_ENDPOINT}`).then(res => {
      context.commit("setBlogPosts", res.data);
    });
  }
};

// mutations
const mutations = {
  setBlogPosts(state, data) {
    state.blogPosts = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced
};